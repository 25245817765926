<template>
  <b-row class="mb-2">
    <b-col cols="1" v-if="(asIndex) == lastServiceIndex || asLength == 1"
           class="align-self-center text-center">
      <b-button variant="outline-primary" pill
                @click="addServiceRow()">
        <font-awesome-icon icon="fa-regular fa-plus" />
      </b-button>
    </b-col>
    <b-col cols="1" v-else class="align-self-center text-center">
      <font-awesome-icon icon="fa-regular fa-caret-right" size="lg" />
    </b-col>
    <b-col cols="9">
      <ValidationProvider :name="$t('Service')" rules="required" v-slot="{ errors }">
        <v-select transition="" v-model="service.serviceId"
                  :reduce="s => s.id" label="name"
                  :filterBy="$root.filterOptions"
                  :searchable="true"
                  :options="serviceList"
                  :class="(errors.length > 0 ? ' is-invalid' : '')"
                  required>
          <template v-slot:option="option">
            <i :class="option.icon"></i>
            {{ option.name }}
          </template>
        <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
        <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
      </ValidationProvider>
    </b-col>
    <b-col cols="2" v-if="lastServiceIndex == 0"></b-col>
    <b-col cols="2" v-else class="align-self-center text-center">
      <b-button variant="danger" @click="removeServiceRow(service)">
        <font-awesome-icon icon="fa-regular fa-xmark" />
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
  import entityService from '../../services/entity'

  export default {
    name: 'AppointmentServie',
    props: {
      asIndex: Number,
      asLength: Number,
      initialAs: Object,
      staffId: String,
      staffBranchCalendarId: Number,
    },
    data() {
      return {
        serviceList: [],
      }
    },
    methods: {
      getServiceList(staffId) {
        if (staffId && staffId.length > 0) {
          entityService.getServiceList(this.service?.serviceId, staffId, this.staffBranchCalendarId)
            .then(response => {
              this.serviceList = response;
            });
        }
      },
      addServiceRow() {
        this.$emit('addService');
      },
      removeServiceRow(item) {
        this.$emit('removeService', item);
      }
    },
    computed: {
      service: function () {
        return this.initialAs;
      },
      lastServiceIndex: function () {
        return this.asLength - 1;
      },
    },
    watch: {
      'staffId': function (staffId) {
        this.getServiceList(staffId);
      },
      'staffBranchCalendarId': function () {

        if (this.staffId)
        this.getServiceList(this.staffId);
      },
      'service.serviceId': function () {
        this.$emit('serviceTotalMinute');
      }
    },
    mounted: function () {
      if (this.staffId) {
        this.getServiceList(this.staffId);
      }
    }
  }
</script>
